export const GtmTriggers = (() => ({

    get_gtm_ecommerce_item: (item_id) => {
        let item = window['gtm_ctx']._item_cache[item_id]
        return item;
    },

    init: (user_id) => {
        if (user_id) {
            window["dataLayer"].push({ user_id })
        }
        const gtm_ctx = JSON.parse(document.getElementById('gtm_ctx').textContent);
        const cache = gtm_ctx['_item_cache'] = {}
        for (let item of gtm_ctx.ecommerce?.items ?? []) {
            if (gtm_ctx.ecommerce.item_list_name) {
                item.item_list_name = gtm_ctx.ecommerce.item_list_name
            }
            if (gtm_ctx.ecommerce.item_list_id) {
                item.item_list_id = gtm_ctx.ecommerce.item_list_id
                if (!item.item_list_name) {
                    item.item_list_name = gtm_ctx.ecommerce.item_list_id
                }
            }
            cache[item.item_id] = item;
        }
        window['gtm_ctx'] = gtm_ctx;
        const gtm_session = JSON.parse(document.getElementById('gtm_session').textContent);
        window['gtm_session'] = gtm_session;
    },

    push_ecommerce_event: (event, event_obj) => {
        const value = event_obj?.ecommerce?.value
        if (value) {
            typeof value == "string"
            event_obj.ecommerce.value = Number(value)
            event_obj.ecommerce.currency = "EUR"
        }
        const customer = window['gtm_ctx']?.customer;
        window["dataLayer"].push({
            ecommerce: null
        });
        window["dataLayer"].push({
            event: event,
            ...event_obj,
            ...(customer ? { customer } : {})
        })
    },

    product_add_or_remove_wishlist: (product_in_wishlist, item_id) => {
        let event = 'dl_add_to_wishlist';
        if (product_in_wishlist === true) {
            event = "dl_remove_from_wishlist";
        }
        const item = GtmTriggers.get_gtm_ecommerce_item(item_id);
        GtmTriggers.push_ecommerce_event(event, {
            ecommerce: {
                value: item.price,
                items: [item]
            }
        });
    },

    select_item: (item_id) => {
        GtmTriggers.push_ecommerce_event('dl_select_item', {
            ecommerce: {
                items: [GtmTriggers.get_gtm_ecommerce_item(item_id)]
            }
        })
    },

    purchase: () => {
        const ecommerce = window['gtm_ctx'].ecommerce;
        GtmTriggers.push_ecommerce_event('dl_purchase', { ecommerce })
    },

    view_item: (item_id) => {
        const item = GtmTriggers.get_gtm_ecommerce_item(item_id);
        GtmTriggers.push_ecommerce_event('dl_view_item', {
            ecommerce: {
                value: item.price,
                items: [item]
            }
        })
    },

    view_cart: () => {
        const ecommerce = window['gtm_ctx'].ecommerce;
        GtmTriggers.push_ecommerce_event('dl_view_cart', {
            ecommerce
        })
    },

    view_item_list: () => {
        const ecommerce = window['gtm_ctx'].ecommerce;
        GtmTriggers.push_ecommerce_event('dl_view_item_list', { ecommerce });
    },

    cart_tracking: () => {
        const gtm_session = window['gtm_session'];
        if (gtm_session?.deleted?.length) {
            GtmTriggers.push_ecommerce_event('dl_remove_from_cart', {
                ecommerce: {
                    value: gtm_session.deleted_value,
                    items: gtm_session.deleted
                }
            });
        }
        if (gtm_session?.added?.length) {
            GtmTriggers.push_ecommerce_event('dl_add_to_cart', {
                ecommerce: {
                    value: gtm_session.added_value,
                    items: gtm_session.added
                }
            });
        }
    },

    begin_checkout: () => {
        const ecommerce = window['gtm_ctx'].ecommerce;
        GtmTriggers.push_ecommerce_event('dl_begin_checkout', { ecommerce })
    },

    set_checkout: () => {
        const session = window['gtm_session'];
        const ecommerce = window['gtm_ctx']['ecommerce'];
        const shipping_tier = session.shipping_method_code;
        if (shipping_tier) {
            GtmTriggers.push_ecommerce_event('add_shipping_info', {
                ecommerce: {
                    ...ecommerce,
                    shipping_tier
                }
            })
        }
    },

    user_logged_id: () => {
        const session = window['gtm_session'];
        const event = session?.event;
        if (event) {
            const payload = session?.payload;
            window["dataLayer"].push({
                event,
                ...payload
            });
        }
    }
}))()
window['GtmTriggers'] = GtmTriggers;
